import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getPostCover,
  LAYOUT_NAME_SIDE_BY_SIDE_MOBILE,
  getOuterUrl,
} from '@wix/communities-blog-client-common';

import Link from '../link/internal-link';
import { getImageContainerSize } from '../../services/get-post-cover-height';
import withDeviceType from '../../hoc/with-device-type';
import VideoEmbed from '../../../post-page/components/video-embed';
import PostListItemImage from '../post-list-item-image';
import PostListItemVideoThumbnail from '../post-list-item-video-thumbnail';
import withSectionUrl from '../../hoc/with-section-url';

/* eslint-disable react/prop-types */
const renderVideoEmbed = ({
  oembed,
  postLink,
  canPlayVideo,
  videoClassName,
  height = 140,
}) => {
  if (!oembed) {
    return null;
  }

  const style = { flexBasis: `${height}px` };

  return canPlayVideo ? (
    <VideoEmbed oembed={oembed} className={videoClassName} style={style} />
  ) : (
    <div className={videoClassName} style={style}>
      <Link tabIndex="-1" to={postLink}>
        <PostListItemVideoThumbnail oembed={oembed} />
      </Link>
    </div>
  );
};

const renderImage = ({
  image,
  url,
  isPublic,
  postLink,
  type,
  imageClassName,
  isMobile,
  useAlternateDimesions,
  width,
  height,
  oembed,
  withoutContainerStyle,
  post,
}) => {
  const sideBySideMobileLayout = type === LAYOUT_NAME_SIDE_BY_SIDE_MOBILE;

  let preparedImageSrc;

  if (image) {
    preparedImageSrc = image;
  } else if (sideBySideMobileLayout && oembed && oembed.thumbnail_url) {
    preparedImageSrc = oembed.thumbnail_url;
  } else {
    return null;
  }

  const imgContainerSize = getImageContainerSize(
    image,
    type,
    useAlternateDimesions,
  );
  const containerStyle =
    isMobile || withoutContainerStyle
      ? {}
      : {
          height: `${height || imgContainerSize.height}px`,
          minHeight: `${height || imgContainerSize.height}px`,
        };

  return (
    <div style={containerStyle} className={imageClassName}>
      <Link
        tabIndex="-1"
        fullRoute={post.link}
        to={postLink}
        aria-hidden={isMobile ? 'true' : undefined}
      >
        <PostListItemImage
          image={preparedImageSrc}
          url={post.link || url}
          isPublic={isPublic}
          height={height || imgContainerSize.height}
          width={width || imgContainerSize.width}
          layoutName={type}
        />
      </Link>
    </div>
  );
};
/* eslint-enable react/prop-types */

export const PostListItemCover = ({
  post,
  postLink,
  type,
  canPlayVideo,
  videoClassName,
  imageClassName,
  isPublic,
  isMobile,
  useAlternateDimesions,
  width,
  height,
  sectionUrl,
  withWrapper,
  wrapperClassName,
  withoutContainerStyle,
}) => {
  const { shouldRender, imageMetadata, videoMetadata } = getPostCover(post);
  if (!shouldRender) {
    return null;
  }

  const cover =
    renderImage({
      image: imageMetadata,
      url: getOuterUrl(postLink, sectionUrl),
      isPublic,
      postLink,
      type,
      imageClassName,
      isMobile,
      useAlternateDimesions,
      width,
      height,
      oembed: videoMetadata,
      withoutContainerStyle,
      post,
    }) ||
    renderVideoEmbed({
      oembed: videoMetadata,
      postLink,
      canPlayVideo,
      videoClassName,
      width,
      height,
    });

  return withWrapper ? <div className={wrapperClassName}>{cover}</div> : cover;
};

PostListItemCover.propTypes = {
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  canPlayVideo: PropTypes.bool,
  videoClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  isPublic: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  useAlternateDimesions: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  sectionUrl: PropTypes.string,
  withWrapper: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  withoutContainerStyle: PropTypes.bool,
};

PostListItemCover.defaultProps = {
  withWrapper: false,
  withoutContainerStyle: false,
};

export default flowRight(
  withDeviceType,
  withSectionUrl,
)(PostListItemCover);
