import PropTypes from 'prop-types';
import React from 'react';

const EmptyLayout = ({ children }) => <div>{children}</div>;

EmptyLayout.propTypes = {
  children: PropTypes.node,
};

export default EmptyLayout;
