import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import EmptyStates from '../../components/empty-states';
import withTranslate from '../../hoc/with-translate';
import { getQueryLocale } from '../../selectors/locale-selectors';

const NoPostsFeed = ({ t, isMultilingual, ...props }) => (
  <EmptyStates
    title={
      isMultilingual
        ? t('no-posts-feed.no-published-posts-multilingual')
        : t('no-posts-feed.on-the-way')
    }
    content={t('no-posts-feed.stay-tuned')}
    {...props}
  />
);

NoPostsFeed.propTypes = {
  t: PropTypes.func,
  isMultilingual: PropTypes.string,
};

const mapStateToProps = ({ state }) => ({
  isMultilingual: Boolean(getQueryLocale(state)),
});

export default flowRight(connect(mapStateToProps), withTranslate)(NoPostsFeed);
